import { Box } from '@mui/material';

const SiteLogo = () => {

  return (
    <Box 
      display="flex" 
      justifyContent="center"
      sx={{
        backgroundColor: '#FFFFFF',
        height: {xs: '64px', md: '88px'},
        width: '100%',
      }}
    >
      <Box
        component="img"
        src="/images/Successfee.svg"
        alt="Successfee Logo"
        sx={{ 
          paddingTop: '16px',
          paddingBottom: {xs: '8px', md: '16px'},
          objectFit: 'contain'
        }}
      />
    </Box>
  );
};

export default SiteLogo;
