import { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { getInviteSrcData } from './Common';
import SiteLogo from './SiteLogo';

type StoreLogoThumbnailAtTopProps = {
  storeLogoThumbnail?: string;
  inviteSite?: string;
};

const StoreLogoThumbnailAtTop = ({
  storeLogoThumbnail: externalLogo,
  inviteSite: externalInviteSite
}: StoreLogoThumbnailAtTopProps) => {
  const [inviteSrc, setInviteSrc] = useState<number | null>(null);
  const [inviteSite, setInviteSite] = useState<string | null>(externalInviteSite ?? null);
  const [storeLogoThumbnail, setStoreLogoThumbnail] = useState<string | null>(externalLogo ?? null);

  useEffect(() => {
    const data = getInviteSrcData();
    setInviteSrc(data);

    if (!externalInviteSite) {
      const site = sessionStorage.getItem('inviteSite');
      setInviteSite(site);
    }

    if (!externalLogo) {
      const sessionLogo = sessionStorage.getItem('inviteImage');
      setStoreLogoThumbnail(sessionLogo);
    }
  }, [externalInviteSite, externalLogo]);

  return (
    <>
      {storeLogoThumbnail && inviteSite ? (
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center"
          sx={{
            backgroundColor: '#FFFFFF',
            height: {xs: '160px', md: '270px'},
            width: '100%'
          }}
        >
          <Box
            component="img"
            src={storeLogoThumbnail}
            alt={inviteSite}
            sx={{
              maxHeight: {xs: '80px', md: '150px' },
              width: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      ) : (
        <Box 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center"
        >
          <Box
            component="img"
            src="/images/Successfee.svg"
            alt="Successfee Logo"
            sx={{
              paddingTop: { xs: '40px', md: '80px' },
              paddingBottom: '40px',
              height: {xs: '160px', md: '270px'},
              width: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      )}
    </>
  );
};

export default StoreLogoThumbnailAtTop;
