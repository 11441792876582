import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2),
  textAlign: 'center',
  width: '100%'
}));

type FooterProps = {
  inviteSite?: string;
};

const Footer: React.FC<FooterProps> = ({ inviteSite: externalInviteSite }) => {
  const [inviteSite, setInviteSite] = useState<string | null>(externalInviteSite ?? null);

  useEffect(() => {
    if (externalInviteSite) {
      setInviteSite(externalInviteSite);
    } else {
      const site = sessionStorage.getItem('inviteSite');
      setInviteSite(site);
    }
  }, [externalInviteSite]);

  return (
    <FooterContainer>
      {inviteSite ? (
        <Typography variant="body1">Copyright © {inviteSite}, All Rights Reserved.</Typography>
      ) : (
        <Typography variant="body1">Copyright © Successfee, Inc. All Rights Reserved.</Typography>
      )}
    </FooterContainer>
  );
};

export default Footer;
