import { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { getInviteSrcData } from './Common';
import SiteLogo from './SiteLogo';

type SiteNameAtTopHeaderProps = {
  storeLogoThumbnail?: string;
  inviteSite?: string;
};

const SiteNameAtTopHeader = ({
  storeLogoThumbnail: externalLogo,
  inviteSite: externalInviteSite
}: SiteNameAtTopHeaderProps) => {
  const [inviteSrc, setInviteSrc] = useState<number | null>(null);
  const [inviteSite, setInviteSite] = useState<string | null>(externalInviteSite ?? null);
  const [storeLogoThumbnail, setStoreLogoThumbnail] = useState<string | null>(externalLogo ?? null);

  useEffect(() => {
    const data = getInviteSrcData();
    setInviteSrc(data);

    if (!externalInviteSite) {
      const site = sessionStorage.getItem('inviteSite');
      setInviteSite(site);
    }

    if (!externalLogo) {
      const sessionLogo = sessionStorage.getItem('inviteImage');
      setStoreLogoThumbnail(sessionLogo);
    }
  }, [externalInviteSite, externalLogo]);

  return (
    <>
      {storeLogoThumbnail && inviteSite ? (
        <Box 
          display="flex" 
          flexDirection="column" 
          justifyContent="center" 
          alignItems="center"
          sx={{
            backgroundColor: '#FFFFFF',
            height: {xs: '64px', sm: '88px'},
            width: '100%'
          }}
        >
          <Box
            component="img"
            src={storeLogoThumbnail}
            alt={inviteSite}
            sx={{
              maxHeight: {xs: '40px', sm: '56px' },
              width: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      ) : (
        <SiteLogo />
      )}
    </>
  );
};

export default SiteNameAtTopHeader;
