import React from 'react';
import { RadioButtonGroupInput, RadioButtonGroupInputProps, useInput } from 'react-admin';

interface NumericRadioButtonGroupInputProps extends Omit<RadioButtonGroupInputProps, 'source'> {
  source: string;
  isLabelRequired?: boolean;
}

const NumericRadioButtonGroupInput: React.FC<NumericRadioButtonGroupInputProps> = ({
  isLabelRequired = true,
  ...props
}) => {
  const { field } = useInput(props);

  const handleOnChange = (...event: any[]) => {
    field.onChange(parseInt(event[1], 10));
  };

  return (
    <RadioButtonGroupInput
      {...props}
      sx={{
        "& .MuiFormControlLabel-label": { fontSize: "14px" },
        ...(isLabelRequired
          ? {} 
          : { "& .css-1yhapad-MuiFormLabel-root": { display: "none" } }),
      }}
      onChange={handleOnChange}
    />
  );
};

export default NumericRadioButtonGroupInput;
