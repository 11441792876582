// ResetPasswordSuccessPage.tsx
import React from 'react';
import { Typography, Stack, Box } from '@mui/material';
import { ActionButton } from '../ActionButton';
import RegisterLayoutWithoutHeader from '../RegisterLayoutWithoutHeader';
import { useNavigate } from 'react-router-dom';


interface ResetPasswordSuccessPageProps {
  backgroundColor?: string;
  buttonColor?: string;
}

const ResetPasswordSuccessPage: React.FC<ResetPasswordSuccessPageProps> = ({
  buttonColor = '#BA7967'
}) => {
  const navigate = useNavigate();

  return (
    <RegisterLayoutWithoutHeader>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{ maxWidth: '600px', width: '100%' }}
          spacing={5}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
        >
          <Typography variant="h5" gutterBottom align="center">
            パスワード変更完了
          </Typography>

          <Typography variant="body1" gutterBottom align="center">
            パスワードの変更が完了しました。
          </Typography>

          <ActionButton
            variant="contained"
            type="submit"
            style={{ backgroundColor: buttonColor }}
            onClick={() => { navigate("/login") }}
          >
            ログインページへ戻る
          </ActionButton>
        </Stack>
      </Box>
    </RegisterLayoutWithoutHeader>
  );
};

export default ResetPasswordSuccessPage;
