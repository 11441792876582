import { Download as DownloadIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useMediaQuery,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip as MuiTooltip
} from '@mui/material';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { MaterialReactTable, MRT_Cell, MRT_ColumnDef, MRT_SortingState } from 'material-react-table';
import { MRT_Localization_JA } from 'material-react-table/locales/ja';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Title, useDataProvider, useTranslate } from 'react-admin';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis
} from 'recharts';
import { ANALYSIS_DATE_TYPE } from '../constants/choices';
import CustomGraphTooltip from './CustomGraphTooltip';
import AffiliateSummary from './AffiliateSummary';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; // DatePicker をインポート
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');

interface DataItem {
  id: string;
  name: string;
  partnerName: string;
  introducerName: string | null;
  salesAmount: number;
  salesCount: number;
  clickCount: number;
  feebackCommission: number;
  partnerCommission: number;
  introducerCommission: number;
  affiliateOwnerType: number;
  totalAffiliateCommission?: number;
}

interface DataEntry {
  date: string;
  items: DataItem[];
}

interface Summary {
  totalSalesAmount: number;
  totalFeebackCommission: number;
  totalPartnerCommission: number;
  totalIntroducerCommission: number;
  totalAffiliateCommission: number;
  totalClickCount: number;
  totalSalesCount: number;
  totalFeebackClaim: number;
}

interface AnalyticsProps {
  selectedResultType?: number;
}

const customLocalization = {
  ...MRT_Localization_JA,
  showHideColumns: '列の表示/非表示',
  showAllColumns: '全て表示',
  hideAllColumns: '全て非表示'
};

const formatNumber = (num: number): string => {
  return Math.floor(num).toLocaleString(); // 小数点以下を切り捨て
};

const integerNumber = (num: number): number => {
  return Math.floor(num);
};

const formatDate = (date: string, dateType: number | undefined): string => {
  const [year, month, day] = date.split('-');
  return dateType === 1 ? `${year}-${month}` : `${year}-${month}-${day}`;
};

const CustomTableTooltip: React.FC<{ item: DataItem }> = ({ item }) => {
  const translate = useTranslate();
  const affiliateDetails =
    item.affiliateOwnerType === 3
      ? [{ partnerName: item.partnerName, introducerCommission: item.introducerCommission }]
      : [];

  return (
    <div>
      <Typography variant="body2">
        {`内${translate('resources.analytics.fields.partnerCommission')}: ${formatNumber(
          item.partnerCommission
        )}${translate('values.graphTargetUnits.partnerCommission')}`}
      </Typography>
      {affiliateDetails.map((detail, index) => (
        <Typography key={index} variant="body2">
          {`内${translate('resources.analytics.fields.introducerCommission')}:`}
          <br />
          {`${detail.partnerName} : ${formatNumber(detail.introducerCommission)}${translate(
            'values.graphTargetUnits.introducerCommission'
          )}`}
        </Typography>
      ))}
    </div>
  );
};

const Analytics: React.FC<AnalyticsProps & { isAdmin?: boolean }> = ({ selectedResultType, isAdmin = true }) => {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [data, setData] = useState<DataEntry[]>([]);
  const [graphTarget] = useState<string>('salesAmount');
  const [startDate, setStartDate] = useState<string>(
    new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0]
  );
  const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [dateError, setDateError] = useState<string | null>(null);
  const [dateType, setDateType] = useState<number>(2);
  const [resultType, setResultType] = useState<number>(1);
  const [isCompany, setIsCompany] = useState(false);
  const [summary, setSummary] = useState<Summary>({
    totalSalesAmount: 0,
    totalFeebackCommission: 0,
    totalPartnerCommission: 0,
    totalIntroducerCommission: 0,
    totalAffiliateCommission: 0,
    totalClickCount: 0,
    totalSalesCount: 0,
    totalFeebackClaim: 0
  });
  const [nameFilter, setNameFilter] = useState<string>('全体');
  const [isShowingAll, setIsShowingAll] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const isSmartPhoneScreen = useMediaQuery('(max-width:550px)');
  const colorScale = scaleOrdinal(schemeCategory10);
  const [clients, setClients] = useState<{ id: number; name: string }[]>([]);

  const fetchClients = useCallback(async () => {
    if (selectedResultType === 2) {
      try {
        const { data } = await dataProvider.getList('clients', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'name', order: 'ASC' },
          filter: {}
        });
        setClients(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    }
  }, [dataProvider, selectedResultType]);

  useEffect(() => {
    // selectedResultType が存在する場合に resultType を設定
    if (selectedResultType) {
      setResultType(selectedResultType);
    }

    // '全体' というフィルターを設定
    setNameFilter('全体');

    // ローカルストレージから 'isCompany' の値を取得して設定
    const storedIsCompany = localStorage.getItem('isCompany');
    if (storedIsCompany) {
      setIsCompany(storedIsCompany === 'true');
    }
    if (selectedResultType === 2) {
      fetchClients();
    }
  }, [selectedResultType, fetchClients]);

  const fetchData = useCallback(async () => {
    try {
      // dateType が 1 の場合、startDate と endDate の日付を調整
      let adjustedStartDate = startDate;
      let adjustedEndDate = endDate;

      if (dateType === 1) {
        // startDate をその月の1日に設定
        const [startYear, startMonth] = startDate.split('-');
        adjustedStartDate = `${startYear}-${startMonth}-01`;

        // endDate もその月の1日に設定（必要に応じて修正）
        const [endYear, endMonth] = endDate.split('-');
        adjustedEndDate = `${endYear}-${endMonth}-01`;
      }

      const reportData = await dataProvider.getReportData(adjustedStartDate, adjustedEndDate, dateType, resultType);
      if (reportData) {
        const summaryData = reportData.summary ?? {
          totalSalesAmount: 0,
          totalFeebackCommission: 0,
          totalPartnerCommission: 0,
          totalIntroducerCommission: 0,
          totalClickCount: 0,
          totalSalesCount: 0,
          totalFeebackClaim: 0
        };
        setSummary({
          ...summaryData,
          totalFeebackClaim:
            Math.floor(summaryData.totalFeebackCommission) + Math.floor(summaryData.totalPartnerCommission),
          totalAffiliateCommission:
            Math.floor(summaryData.totalPartnerCommission) + Math.floor(summaryData.totalIntroducerCommission)
        });
        setData(reportData.data ?? []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [dataProvider, startDate, endDate, dateType, resultType]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleInputChange =
    (
      setter: React.Dispatch<React.SetStateAction<string>>,
      relatedSetter?: React.Dispatch<React.SetStateAction<string>>,
      comparisonDate?: string,
      isStartDate?: boolean
    ) =>
    (newDate: Dayjs | null) => {
      if (!newDate) return;

      const formattedDate = newDate.format('YYYY-MM-DD');

      // 動的な最小・最大の日付を設定
      const minDate = '2024-05-01';
      const maxDate = dayjs().format('YYYY-MM-DD'); // 現在の日付

      // 日付が範囲内かどうかチェック
      if (formattedDate < minDate || formattedDate > maxDate) {
        setDateError(`日付は ${minDate} から ${maxDate} の間で入力してください。`);
      } else {
        setDateError(null); // エラーをクリア

        // setterを使って日付を設定
        setter(formattedDate);

        // 関連する日付（startDate または endDate）を更新
        if (relatedSetter && comparisonDate) {
          if (isStartDate) {
            // startDateがendDateよりも後であればendDateを修正
            if (new Date(formattedDate) > new Date(comparisonDate)) {
              relatedSetter(formattedDate);
            }
          } else {
            // endDateがstartDateよりも前であればstartDateを修正
            if (new Date(formattedDate) < new Date(comparisonDate)) {
              relatedSetter(formattedDate); // startDateをendDateに合わせる
            }
          }
        }
      }
    };

  const handleButtonClick = (setter: React.Dispatch<React.SetStateAction<number>>, value: number) => () => {
    setter(value);
  };

  const handleNameFilterChange = (event: SelectChangeEvent) => {
    setNameFilter(event.target.value);
  };

  const handleSortingChange = (updaterOrValue: MRT_SortingState | ((old: MRT_SortingState) => MRT_SortingState)) => {
    setSorting((oldSorting) => (typeof updaterOrValue === 'function' ? updaterOrValue(oldSorting) : updaterOrValue));
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadCSV = () => {
    const headers = columns.map((col) => col.header);
    const rows = sortedData.map((row) =>
      columns
        .map((col) => {
          const value = row[col.accessorKey as keyof DataItem];
          return typeof value === 'number' ? `"${formatNumber(value)}"` : `"${value}"`;
        })
        .join(',')
    );

    const csvContent = [headers.join(','), ...rows].join('\n');

    const bom = '\uFEFF';
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${bom}${csvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `アナリティクス(${startDate}~${endDate}).csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredGraphData = useMemo(() => {
    return data.map((entry) => {
      const transformedEntry = {
        date: formatDate(entry.date, dateType),
        salesAmount: 0,
        adjustedSalesAmount: 0, //グラフに表示用(salesAmountだと積み上げ棒グラフにしたらずれる)
        feebackCommission: 0,
        partnerCommission: 0,
        introducerCommission: 0,
        affiliateCommission: 0, //partnerCommission +introducerCommission
        affiliateDetails: [] as { partnerName: string; introducerCommission: number }[]
      };
      entry.items.forEach((item) => {
        transformedEntry.salesAmount += item.salesAmount;
        transformedEntry.feebackCommission += item.feebackCommission;
        transformedEntry.partnerCommission += item.partnerCommission;
        transformedEntry.introducerCommission += item.introducerCommission;
        transformedEntry.affiliateCommission += item.partnerCommission + item.introducerCommission;
        if (item.affiliateOwnerType === 3) {
          transformedEntry.affiliateDetails.push({
            partnerName: item.partnerName,
            introducerCommission: item.introducerCommission
          });
        }
      });
      transformedEntry.salesAmount = Math.floor(transformedEntry.salesAmount);
      transformedEntry.feebackCommission = Math.floor(transformedEntry.feebackCommission);
      transformedEntry.partnerCommission = Math.floor(transformedEntry.partnerCommission);
      transformedEntry.introducerCommission = Math.floor(transformedEntry.introducerCommission);
      transformedEntry.affiliateCommission = Math.floor(transformedEntry.affiliateCommission);

      transformedEntry.adjustedSalesAmount = Math.floor(
        transformedEntry.salesAmount - transformedEntry.feebackCommission - transformedEntry.affiliateCommission
      );
      return transformedEntry;
    });
  }, [data, dateType]);

  const cumulativeData = useMemo(() => {
    let cumulativePartnerCommission = 0;
    let cumulativeIntroducerCommission = 0;

    return data.map((entry) => {
      const partnerCommission = entry.items.reduce((acc, item) => acc + item.partnerCommission, 0);
      const introducerCommission = entry.items.reduce((acc, item) => acc + item.introducerCommission, 0);

      cumulativePartnerCommission += partnerCommission;
      if (isCompany) {
        cumulativeIntroducerCommission += introducerCommission;
      }

      const result: { date: string; partnerCommission: number; introducerCommission?: number } = {
        date: formatDate(entry.date, dateType),
        partnerCommission: integerNumber(cumulativePartnerCommission)
      };

      if (isCompany) {
        result.introducerCommission = integerNumber(cumulativeIntroducerCommission);
      }

      return result;
    });
  }, [data, dateType, isCompany]);

  const filteredTableData = useMemo(() => {
    // 全体のsalesAmountを日付ごとに集計
    const totalSalesAmountByDate = data.reduce((acc, entry) => {
      const totalSalesAmountForDate = entry.items.reduce((itemAcc, item) => itemAcc + item.salesAmount, 0);
      acc[entry.date] = totalSalesAmountForDate;
      return acc;
    }, {} as Record<string, number>); // 日付ごとの totalSalesAmount を記録するオブジェクト

    if (!nameFilter || nameFilter === '全体') {
      // nameFilter が '全体' の場合、各エントリ（DataEntry）の items を集計し、新しい配列として返す
      return data.map((entry) => ({
        id: '',
        name: '全体',
        date: entry.date,
        salesAmount: entry.items.reduce((acc, item) => acc + item.salesAmount, 0),
        salesCount: entry.items.reduce((acc, item) => acc + item.salesCount, 0),
        feebackCommission: entry.items.reduce((acc, item) => acc + item.feebackCommission, 0),
        partnerCommission: entry.items.reduce((acc, item) => acc + item.partnerCommission, 0),
        introducerCommission: entry.items.reduce((acc, item) => acc + item.introducerCommission, 0),
        totalAffiliateCommission: entry.items.reduce(
          (acc, item) => acc + item.partnerCommission + item.introducerCommission,
          0
        ),
        clickCount: entry.items.reduce((acc, item) => acc + item.clickCount, 0),
        partnerName: '',
        introducerName: null,
        affiliateOwnerType: 0
      }));
    }

    // nameFilter に一致するアイテムを抽出し、それぞれに date と totalAffiliateCommission を加えた配列を返す
    return data
      .flatMap((entry) =>
        entry.items.map((item) => ({
          ...item,
          date: entry.date,
          totalAffiliateCommission: item.partnerCommission + item.introducerCommission
        }))
      )
      .filter((item) => item.name === nameFilter)
      .map((item) => {
        const totalSalesAmountForDate = totalSalesAmountByDate[item.date]; // 日付ごとの totalSalesAmount を取得
        const salesAmountRate = totalSalesAmountForDate ? (item.salesAmount / totalSalesAmountForDate) * 100 : 0; // 日付ごとの割合を計算

        return {
          ...item,
          salesAmountRate: salesAmountRate.toFixed(2)
        };
      });
  }, [data, nameFilter]);

  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: 'date', desc: true } // 初期ソートを適切に設定（例: date）
  ]);

  const sortedData = useMemo(() => {
    let sorted = [...filteredTableData];
    sorting.forEach(({ id, desc }) => {
      sorted = sorted.sort((a, b) => {
        const aValue = a[id as keyof DataItem];
        const bValue = b[id as keyof DataItem];

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return desc ? bValue - aValue : aValue - bValue;
        } else if (typeof aValue === 'string' && typeof bValue === 'string') {
          return desc ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue);
        }
        return 0;
      });
    });
    return sorted;
  }, [filteredTableData, sorting]);

  const columns = useMemo<MRT_ColumnDef<DataItem>[]>(() => {
    const dateColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.date') || '',
      accessorKey: 'date',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => {
        const dateValue = cell.getValue<string>();
        const formattedDate = formatDate(dateValue, dateType);
        return (
          <>
            {isSmallScreen && (
              <Typography variant="caption">
                {translate('resources.analytics.fields.date')}
                <br />
              </Typography>
            )}
            {formattedDate}
          </>
        );
      }
    };

    const salesAmountColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.salesAmount') || '',
      accessorKey: 'salesAmount',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => (
        <>
          {isSmallScreen && (
            <Typography variant="caption">
              {translate('resources.analytics.fields.salesAmount')}
              <br />
            </Typography>
          )}
          {`${formatNumber(cell.getValue<number>())} ${translate('values.graphTargetUnits.salesAmount')}`}
        </>
      )
    };

    const salesCountColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.salesCount') || '',
      accessorKey: 'salesCount',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => (
        <>
          {isSmallScreen && (
            <Typography variant="caption">
              {translate('resources.analytics.fields.salesCount')}
              <br />
            </Typography>
          )}
          {`${formatNumber(cell.getValue<number>())} ${translate('values.graphTargetUnits.salesCount')}`}
        </>
      )
    };

    const salesAmountRateColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.salesAmountRate') || '',
      accessorKey: 'salesAmountRate',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => (
        <>
          {isSmallScreen && (
            <Typography variant="caption">
              {translate('resources.analytics.fields.salesAmountRate')}
              <br />
            </Typography>
          )}
          {`${cell.getValue<string>()}%`}
        </>
      )
    };

    const feebackCommissionColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.feebackCommission') || '',
      accessorKey: 'feebackCommission',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => (
        <>
          {isSmallScreen && (
            <Typography variant="caption">
              {translate('resources.analytics.fields.feebackCommission')}
              <br />
            </Typography>
          )}
          {`${formatNumber(cell.getValue<number>())} ${translate('values.graphTargetUnits.feebackCommission')}`}
        </>
      )
    };

    const totalAffiliateCommissionColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.affiliateCommission') || '',
      accessorKey: 'totalAffiliateCommission',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => {
        const item = cell.row.original;
        const shouldShowTooltip = selectedResultType === 4;
        return (
          <>
            {isSmallScreen && (
              <Typography variant="caption">
                {translate('resources.analytics.fields.affiliateCommission')}
                <br />
              </Typography>
            )}
            {shouldShowTooltip ? (
              <MuiTooltip title={<CustomTableTooltip item={item} />}>
                <span>{`${formatNumber(item.totalAffiliateCommission ?? 0)} ${translate(
                  'values.graphTargetUnits.affiliateCommission'
                )}`}</span>
              </MuiTooltip>
            ) : (
              <span>{`${formatNumber(item.totalAffiliateCommission ?? 0)} ${translate(
                'values.graphTargetUnits.affiliateCommission'
              )}`}</span>
            )}
          </>
        );
      }
    };

    const clickCountColumn: MRT_ColumnDef<DataItem> = {
      header: translate('resources.analytics.fields.clickCount') || '',
      accessorKey: 'clickCount',
      size: 0,
      Cell: ({ cell }: { cell: MRT_Cell<DataItem> }) => (
        <>
          {isSmallScreen && (
            <Typography variant="caption">
              {translate('resources.analytics.fields.clickCount')}
              <br />
            </Typography>
          )}
          {`${formatNumber(cell.getValue<number>())} ${translate('values.graphTargetUnits.clickCount')}`}
        </>
      )
    };

    // カラムを必要な順番に並べる
    let columnsList: MRT_ColumnDef<DataItem>[] = [];

    if (selectedResultType === 4) {
      // selectedResultType が 4 の場合
      columnsList = [dateColumn, totalAffiliateCommissionColumn, salesAmountColumn, salesCountColumn, clickCountColumn];
    } else if (nameFilter !== '全体' && selectedResultType !== 4) {
      // nameFilter が '全体' でなく、かつ selectedResultType が 4 でない場合
      columnsList = [
        dateColumn,
        salesAmountColumn,
        salesCountColumn,
        feebackCommissionColumn,
        totalAffiliateCommissionColumn,
        clickCountColumn,
        salesAmountRateColumn
      ];
    } else {
      // それ以外の場合
      columnsList = [
        dateColumn,
        salesAmountColumn,
        salesCountColumn,
        feebackCommissionColumn,
        totalAffiliateCommissionColumn,
        clickCountColumn
      ];
    }

    return columnsList;
  }, [selectedResultType, translate, isSmallScreen, dateType, nameFilter]);

  return (
    <Card sx={{ pl: 2, pr: 2 }}>
      <Title title={translate('resources.analytics.name.analytics')} />
      <Stack mt={1}>
        <Stack>
          {selectedResultType === 4 && <AffiliateSummary />}
          <Stack display="flex" flexDirection="column" mt={1}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              指定期間内の実績
            </Typography>
            <Stack display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={1}>
              <Stack display="flex" flexDirection={'row'} justifyContent="left" alignItems="flex-start" gap={1}>
                <Box display="flex" alignItems="flex-start" gap={0.5}>
                  <DatePicker
                    label={translate('resources.analytics.fields.startDate')}
                    value={dayjs(startDate)}
                    onChange={handleInputChange(setStartDate, setEndDate, endDate, true)}
                    maxDate={dayjs()}
                    minDate={dayjs('2024-05-01')}
                    format="YYYY-MM-DD"
                    slotProps={{
                      textField: {
                        helperText: dateError,
                        error: !!dateError,
                        sx: { maxWidth: 140 },
                        FormHelperTextProps: { sx: { whiteSpace: 'normal' } }
                      }
                    }}
                  />
                  <Typography variant="body1" mt={2}>
                    ～
                  </Typography>
                  <DatePicker
                    label={translate('resources.analytics.fields.endDate')}
                    value={dayjs(endDate)}
                    onChange={handleInputChange(setEndDate, setStartDate, startDate, false)}
                    maxDate={dayjs()}
                    minDate={dayjs('2024-05-01')}
                    format="YYYY-MM-DD"
                    slotProps={{
                      textField: {
                        helperText: dateError,
                        error: !!dateError,
                        sx: { maxWidth: 140 },
                        FormHelperTextProps: { sx: { whiteSpace: 'normal' } }
                      }
                    }}
                  />
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center" mt={1} gap={3}>
                  <ButtonGroup
                    variant="outlined"
                    aria-label="outlined button group"
                    size={isSmallScreen ? 'small' : 'large'}
                    orientation={isSmallScreen ? 'vertical' : 'horizontal'} // スクリーンサイズによってレイアウトを変更
                  >
                    {ANALYSIS_DATE_TYPE.map((type) => (
                      <Button
                        key={type.id}
                        onClick={handleButtonClick(setDateType, type.id)}
                        variant={dateType === type.id ? 'contained' : 'outlined'}
                      >
                        {type.name}
                      </Button>
                    ))}
                  </ButtonGroup>
                </Box>
              </Stack>
              {(selectedResultType === 2 || selectedResultType === 3) && (
                <Box>
                  <FormControl>
                    <InputLabel id="name-select-label">
                      {selectedResultType === 2
                        ? translate('resources.analytics.fields.selectClient')
                        : selectedResultType === 3
                        ? translate('resources.analytics.fields.selectPartner')
                        : translate('resources.analytics.fields.name')}
                    </InputLabel>
                    <Select
                      labelId="name-select-label"
                      value={nameFilter}
                      label={
                        selectedResultType === 2
                          ? translate('resources.analytics.fields.selectClient')
                          : selectedResultType === 3
                          ? translate('resources.analytics.fields.selectPartner')
                          : translate('resources.analytics.fields.name')
                      }
                      onChange={handleNameFilterChange}
                      sx={{ 
                        maxWidth: { xs: '280px', sm: '310px', md: '365px' } 
                      }}
                    >
                      <MenuItem value="全体">全体</MenuItem>

                      {selectedResultType === 2 && clients.length > 0
                        ? clients
                            .sort((a, b) => a.id.toString().localeCompare(b.id.toString())) // IDを文字列に変換して比較
                            .map((client) => (
                              <MenuItem key={client.id} value={client.name}>
                                {client.name}
                              </MenuItem>
                            ))
                        : data
                            .flatMap((entry) => entry.items)
                            .sort((a, b) => a.id.toString().localeCompare(b.id.toString()))
                            .filter((item, index, self) => self.findIndex((i) => i.id === item.id) === index)
                            .map((item) => (
                              <MenuItem key={item.id} value={item.name}>
                                {item.name}
                              </MenuItem>
                            ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
            </Stack>
          </Stack>

          {(selectedResultType === 1 || selectedResultType === 4) && (
            <>
              <Stack>
                <Stack display="flex" flexDirection="column">
                  <Grid container>
                    {/* 最初のグリッドアイテムラベル  */}
                    {selectedResultType === 1 && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box display="flex" justifyContent="flex-start" width="100%">
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: 'left',
                              fontSize: isSmallScreen ? '0.7rem' : '1rem',
                              whiteSpace: 'pre-wrap',
                              overflowWrap: 'break-word'
                            }}
                          >
                            {isSmartPhoneScreen && translate('resources.analytics.fields.totalSalesAmount').length > 10
                              ? translate('resources.analytics.fields.totalSalesAmount').slice(0, 10) +
                                '\n' +
                                translate('resources.analytics.fields.totalSalesAmount').slice(10)
                              : translate('resources.analytics.fields.totalSalesAmount')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {isAdmin && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box display="flex" justifyContent="flex-start" width="100%">
                          <Typography
                            variant="caption"
                            sx={{
                              textAlign: 'left',
                              fontSize: isSmallScreen ? '0.7rem' : '1rem',
                              whiteSpace: 'pre-wrap',
                              overflowWrap: 'break-word'
                            }}
                          >
                            {isSmartPhoneScreen &&
                            selectedResultType === 1 &&
                            translate('resources.analytics.fields.totalFeebackClaim').length > 10
                              ? translate('resources.analytics.fields.totalFeebackClaim').slice(0, 10) +
                                '\n' +
                                translate('resources.analytics.fields.totalFeebackClaim').slice(10)
                              : selectedResultType === 1
                              ? translate('resources.analytics.fields.totalFeebackClaim')
                              : translate('resources.analytics.fields.totalAffiliateCommission')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                      <Box display="flex" justifyContent="flex-start" width="100%">
                        <Typography
                          variant="caption"
                          sx={{
                            textAlign: 'left',
                            fontSize: isSmallScreen ? '0.7rem' : '1rem',
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'break-word'
                          }}
                        >
                          {isSmartPhoneScreen &&
                          selectedResultType === 1 &&
                          translate('resources.analytics.fields.totalFeebackCommission').length > 10
                            ? translate('resources.analytics.fields.totalFeebackCommission').slice(0, 10) +
                              '\n' +
                              translate('resources.analytics.fields.totalFeebackCommission').slice(10)
                            : selectedResultType === 1
                            ? translate('resources.analytics.fields.totalFeebackCommission')
                            : translate('resources.analytics.fields.totalPartnerCommission')}
                        </Typography>
                      </Box>
                    </Grid>
                    {(selectedResultType !== 4 || (selectedResultType === 4 && isCompany)) && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box display="flex" justifyContent="flex-start" width="100%">
                          <Typography
                            variant="caption"
                            sx={{ textAlign: 'left', fontSize: isSmallScreen ? '0.7rem' : '1rem' }}
                          >
                            {selectedResultType === 1
                              ? translate('resources.analytics.fields.totalAffiliateCommission')
                              : translate('resources.analytics.fields.totalIntroducerCommission')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>

                  <Grid container>
                    {/* 最初のグリッドアイテムデータ */}
                    {selectedResultType === 1 && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box width="100%">
                          <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                            {formatNumber(summary.totalSalesAmount)}
                            {translate('values.graphTargetUnits.salesAmount')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {/* 2番目のグリッドアイテムデータ: isAdmin が true の場合のみ表示 */}
                    {isAdmin && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box width="100%">
                          <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                            {selectedResultType === 1
                              ? formatNumber(summary.totalFeebackClaim)
                              : formatNumber(summary.totalAffiliateCommission)}
                            {translate('values.graphTargetUnits.salesAmount')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {/* 3番目のグリッドアイテムデータ */}
                    <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                      <Box width="100%">
                        <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                          {selectedResultType === 1
                            ? formatNumber(summary.totalFeebackCommission)
                            : formatNumber(summary.totalPartnerCommission)}
                          {translate('values.graphTargetUnits.salesAmount')}
                        </Typography>
                      </Box>
                    </Grid>

                    {/* 4番目のグリッドアイテムデータ */}
                    {/* パートナーの時はisCompanyがTrueの時のみ */}
                    {(selectedResultType !== 4 || (selectedResultType === 4 && isCompany)) && (
                      <Grid item xs={selectedResultType === 1 ? (isAdmin ? 3 : 4) : 4}>
                        <Box width="100%">
                          <Typography variant={isSmallScreen ? 'body1' : 'h5'} sx={{ textAlign: 'left' }}>
                            {selectedResultType === 1
                              ? formatNumber(summary.totalAffiliateCommission)
                              : formatNumber(summary.totalIntroducerCommission)}
                            {translate('values.graphTargetUnits.salesAmount')}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Stack>
              </Stack>
              <ResponsiveContainer height={isSmallScreen ? 200 : 350}>
                {selectedResultType === 4 ? (
                  <AreaChart data={cumulativeData} margin={{ top: 16 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={(tick) => formatDate(tick, dateType)} />
                    <YAxis
                      width={80}
                      tickFormatter={(tick) =>
                        tick >= 1000000
                          ? `${(tick / 1000000).toLocaleString()}M ${translate(
                              'values.graphTargetUnits.partnerCommission'
                            )}`
                          : `${tick.toLocaleString()} ${translate('values.graphTargetUnits.partnerCommission')}`
                      }
                    />
                    <RechartsTooltip
                      content={<CustomGraphTooltip graphTarget={graphTarget} selectedResultType={selectedResultType} />}
                    />
                    <Legend
                      formatter={(value) => (
                        <span style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
                          {translate(`resources.analytics.fields.${value}`)}
                        </span>
                      )}
                    />
                    <Area type="monotone" dataKey="partnerCommission" stroke="#8884d8" fill="#8884d8" />
                    {isCompany && (
                      <Area type="monotone" dataKey="introducerCommission" stroke="#82ca9d" fill="#82ca9d" />
                    )}
                  </AreaChart>
                ) : (
                  <BarChart data={filteredGraphData} margin={{ top: 16 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" tickFormatter={(tick) => formatDate(tick, dateType)} />
                    <YAxis
                      width={80}
                      tickFormatter={(tick) =>
                        tick >= 1000000
                          ? `${(tick / 1000000).toLocaleString()}M ${translate(
                              `values.graphTargetUnits.${graphTarget}`
                            )}`
                          : `${tick.toLocaleString()} ${translate(`values.graphTargetUnits.${graphTarget}`)}`
                      }
                    />
                    <RechartsTooltip
                      content={<CustomGraphTooltip graphTarget={graphTarget} selectedResultType={selectedResultType} />}
                      payload={filteredGraphData.map((entry) => ({
                        name: '',
                        value: 0,
                        payload: {
                          salesAmount: entry.salesAmount,
                          feebackCommission: entry.feebackCommission,
                          affiliateCommission: entry.affiliateCommission
                        }
                      }))}
                    />
                    <Legend
                      formatter={(value) => (
                        <span style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}>
                          {translate(`resources.analytics.fields.${value}`)}
                        </span>
                      )}
                    />
                    <Bar dataKey="adjustedSalesAmount" stackId="a" fill={colorScale('salesAmount')} />
                    <Bar dataKey="feebackCommission" stackId="a" fill={colorScale('feebackCommission')} />
                    <Bar dataKey="affiliateCommission" stackId="a" fill={colorScale('affiliateCommission')} />
                  </BarChart>
                )}
              </ResponsiveContainer>
            </>
          )}
        </Stack>
        <Stack display="flex" flexDirection="column" gap={1} mt={1}>
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>
              {nameFilter && (
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  明細
                </Typography>
              )}
            </Box>
            {isSmallScreen ? (
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <Button variant="outlined" onClick={handleClick}>
                    並び替え
                  </Button>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                    {columns.map((column) => {
                      const isActive = sorting.find((s) => s.id === column.accessorKey);
                      const currentSortOrder = isActive?.desc ? 'desc' : 'asc';
                      return (
                        <MenuItem key={column.accessorKey}>
                          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                            <Typography
                              sx={{
                                mr: 2,
                                color: isActive ? 'blue' : 'inherit',
                                fontWeight: isActive ? 'bold' : 'normal'
                              }}
                            >
                              {column.header}
                            </Typography>
                            <ToggleButtonGroup
                              value={isActive ? currentSortOrder : null}
                              exclusive
                              onChange={(event, newOrder) => {
                                setSorting([{ id: column.accessorKey as string, desc: newOrder === 'desc' }]);
                                handleClose();
                              }}
                              size="small"
                              aria-label={`${column.header} ソート順`}
                            >
                              <ToggleButton
                                value="asc"
                                aria-label="昇順"
                                sx={{
                                  backgroundColor: isActive && currentSortOrder === 'asc' ? 'lightblue' : 'inherit',
                                  fontWeight: isActive && currentSortOrder === 'asc' ? 'bold' : 'normal'
                                }}
                              >
                                昇順
                              </ToggleButton>
                              <ToggleButton
                                value="desc"
                                aria-label="降順"
                                sx={{
                                  backgroundColor: isActive && currentSortOrder === 'desc' ? 'lightblue' : 'inherit',
                                  fontWeight: isActive && currentSortOrder === 'desc' ? 'bold' : 'normal'
                                }}
                              >
                                降順
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>

                <Box>
                  <Button variant="text" color="primary" onClick={downloadCSV} startIcon={<DownloadIcon />}>
                    CSVダウンロード
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box marginLeft="auto">
                <Button
                  variant="text"
                  color="primary"
                  onClick={downloadCSV}
                  startIcon={<DownloadIcon />} // アイコンを追加
                >
                  CSVダウンロード
                </Button>
              </Box>
            )}
          </Box>
          <MaterialReactTable
            columns={columns}
            data={isShowingAll ? sortedData : sortedData.slice(0, 10)}
            enableSorting={true}
            state={{ sorting }}
            onSortingChange={handleSortingChange}
            initialState={{
              density: 'compact'
            }}
            muiTableHeadProps={{
              sx: {
                display: isSmallScreen ? 'none' : 'table-header-group'
              }
            }}
            muiTableBodyRowProps={{
              sx: isSmallScreen
                ? {
                    display: 'block',
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    marginBottom: '2px',
                    padding: '2px',
                    backgroundColor: '#fff'
                  }
                : {},
              hover: true
            }}
            muiTableBodyCellProps={{
              sx: isSmallScreen
                ? {
                    display: 'block',
                    width: '100%',
                    padding: '0px 4px ',
                    '&:not(:last-child)': {
                      borderBottom: 'none'
                    }
                  }
                : {}
            }}
            localization={customLocalization}
            enableColumnActions={false}
            enablePagination={false}
            enableTopToolbar={false} // トップツールバーを非表示
            enableBottomToolbar={false}
          />

          {filteredTableData.length > 10 && (
            <Button onClick={() => setIsShowingAll(!isShowingAll)}>{isShowingAll ? '少なく表示' : 'さらに表示'}</Button>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default Analytics;
