import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslate } from 'react-admin';
import RegisterLayoutWithoutHeader from './RegisterLayoutWithoutHeader';

const RegisterError: React.FC = () => {
  const translate = useTranslate();
  
  return (
    <RegisterLayoutWithoutHeader>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Error')}</title>
        </Helmet>
        <Box display="flex" alignItems="center" sx={{ padding: 2 }}>
          <ErrorOutlineIcon sx={{ marginRight: 1 }} />
          <Typography variant="h5" align="left" sx={{ fontFamily: 'Noto Sans JP' }}>
            無効なURLです。
          </Typography>
        </Box>
      </Container>
    </RegisterLayoutWithoutHeader>
  );
};

export default RegisterError;
