import { AppBar, AppBarProps, useTranslate } from 'react-admin';
import { Typography, Box } from '@mui/material';
import CustomUserMenu from './CustomUserMenu';

const CustomAppBar = (props: AppBarProps) => {
  const translate = useTranslate();

  return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
        <Typography variant="h6" color="inherit" id="react-admin-title">
          {translate('Role')}
        </Typography>
        <Box display="flex" alignItems="center">
          {/* 他のアプリバーコンテンツ（更新ボタンなど）がある場合はここに追加 */}
        </Box>
      </Box>
    </AppBar>
  );
};

export default CustomAppBar;
